import React from "react";

import { RadioGroup, Radio, FormControl, FormControlLabel } from "@mui/material";

import { Icon } from "utils/fontawesome";

/// ROW Component

const MatrixRow = ({ item, value, onChange }) => {
  const { title, description, icon, color } = item;

  return (
    <>
      <div className="d-flex justify-content-between">
        <span className="px-2 h5">
          <Icon icon={icon} color={color} />
          <strong className="px-2">{title}</strong>
          <br />
        </span>
      </div>
      <p className="p-2">{description}</p>
      <div className="px-2 py-1">
        <FormControl>
          <RadioGroup
            row
            name={item.title}
            value={Number(value ?? -1)}
            onChange={(e, value) => {
              onChange({ ...item, value: Number(value ?? -1) });
            }}
          >
            <FormControlLabel
              key={"none"}
              value={String("-1")}
              control={<Radio />}
              label={"None"}
            />

            {Object.values(item?.options ?? {}).map((mark, index) => (
              <FormControlLabel
                key={mark.title}
                value={String(index)}
                control={<Radio />}
                label={mark.title}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </div>
    </>
  );
};

export default MatrixRow;
