import React, { useState, useEffect } from "react";

import { Form, Pagination } from "react-bootstrap";

import RoundForm from "./RoundForm";
import AddQuestionForm from "./AddQuestionForm";
import GameService from "services/GameService";

const DEFAULT_MATCH_VALUE = 1000;
const DEFAULT_MODE = "points";
const DEFAULT_SCHEME = "turbo";
const DEFAULT_CATEGORIES = [];
const DEFAULT_SHUFFLE = true;

function NewGame({ game, onChange }) {
  const [name, setName] = useState(game?.name ?? "");
  const [questions, setQuestions] = useState(game?.questions ?? {});
  const [page, setPage] = useState(0);
  // An integer representing The number of rounds to play
  const [rounds, setRounds] = useState(game?.rounds?.length ?? 1);
  // Game configurations per individual round
  const [configs, setConfigs] = useState(
    game?.rounds || [
      {
        id: 0,
        matchValue: DEFAULT_MATCH_VALUE,
        models: DEFAULT_CATEGORIES,
        mode: DEFAULT_MODE,
        scheme: DEFAULT_SCHEME,
        shuffle: DEFAULT_SHUFFLE,
      },
    ]
  );

  useEffect(() => {
    function handleChange() {
      onChange({
        id: game?.id,
        name: name,
        configs: configs,
        questions: questions,
      });
    }

    handleChange();
  }, [game, name, page, rounds, configs, questions, onChange]);

  const askPreGameQuestions = true;
  const askPostGameQuestions = true;

  const [pageSteps, configView] = React.useMemo(() => {
    function configForIndex(index) {
      var tmpConfig = configs[index] || {
        matchValue: null,
        scheme: null,
        models: null,
        mode: null,
        shuffle: null,
      };
      return prepopulateConfigAtIndex(tmpConfig, index);
    }

    // iterates thru the models in reverse order, to get the last models selected
    function prepopulateConfigAtIndex(config, index) {
      for (var i = index; i > 0; i--) {
        var prevConfig = Object.assign({}, configs[i - 1]);
        // attempt to assign the previous config's matchValue to the current
        if (!config.matchValue && !!prevConfig.matchValue) {
          config.matchValue = prevConfig.matchValue ?? 1000;
        }
        // attempt to assign the previous configs models to the current
        if (!config.models && !!prevConfig.models) {
          config.models = prevConfig.models;
        }

        // attempt to assign the previous configs models to the current
        if (!config.scheme && !!prevConfig.scheme) {
          config.scheme = prevConfig.scheme ?? DEFAULT_SCHEME;
        }

        // attempt to assign the previous configs mode to the current
        if (!config.mode && !!prevConfig.mode) {
          config.mode = prevConfig.mode || DEFAULT_MODE;
        }

        // attempt to assign the previous configs mode to the current
        if (config.shuffle === null && !!prevConfig.shuffle) {
          config.shuffle = prevConfig.shuffle ?? DEFAULT_SHUFFLE;
        }

        if (!config.id) {
          config.id = index;
        }
      }
      return config;
    }

    function handleConfigChanged(e) {
      function sanitizeModels(models, mode) {
        return models?.map((model) => {
          let multiplier;
          if (mode === "donut") {
            multiplier = {
              low: parseFloat(model.multiplier?.low) || 1,
              high: parseFloat(model.multiplier?.high) || 1,
            };
          } else {
            multiplier = parseFloat(model.multiplier) || 1;
          }
          return { ...model, multiplier };
        });
      }

      var results = [...configs];
      var ele = results[page];
      ele = { ...ele, ...e };

      const newMode = e.mode || ele.mode;

      results[page] = {
        ...ele,
        models: sanitizeModels(ele?.models, newMode),
      };

      setConfigs(results);
    }

    const steps = [];
    const view = {};

    function createPage({ id, name }) {
      return (
        <Pagination.Item key={id} active={page === id} onClick={() => setPage(id)}>
          {name ?? `Round ${id + 1}`}
        </Pagination.Item>
      );
    }

    // Add Pre Game Questions page if needed
    if (askPreGameQuestions) {
      steps.push(createPage({ id: "pre", name: "Pre Game Qs" }));
      view["pre"] = (
        <AddQuestionForm
          questions={questions.pre}
          setQuestions={(value) => setQuestions({ ...questions, pre: value })}
        />
      );
    }

    // Add Round pages
    for (let i = 0; i < rounds; i++) {
      const roundId = i;
      steps.push(createPage({ id: roundId }));
      view[roundId] = (
        <RoundForm config={configForIndex(roundId) ?? {}} onChange={handleConfigChanged} />
      );
    }

    // Add Post Game Questions page if needed
    if (askPostGameQuestions) {
      steps.push(createPage({ id: "post", name: "Post Game Qs" }));
      view["post"] = (
        <AddQuestionForm
          questions={questions.post}
          setQuestions={(value) => setQuestions({ ...questions, post: value })}
        />
      );
    }

    return [steps, view];
  }, [askPreGameQuestions, askPostGameQuestions, configs, page, questions, rounds]);

  // Final render of the Form Component
  return (
    <>
      <Form>
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Name</Form.Label>
          <Form.Control
            value={name}
            onChange={(e) => setName(e.target.value)}
            type="text"
            placeholder="Name of the game"
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="route">
          <Form.Label>
            Route{" "}
            <sup>
              <small>{game?.route == null && "⚠️ publish game before sharing link"}</small>
            </sup>
          </Form.Label>
          <Form.Control
            value={`https://esgmachine.com/${GameService.nameToRoute(name)}`}
            disabled
            type="text"
            placeholder="Game route"
          />
        </Form.Group>
        {/* {RoundsSection()} */}
        <Pagination className={"justify-content-center"}>
          {pageSteps}
          <Pagination.Item
            key={"plus"}
            onClick={(event) => {
              setRounds(Number(rounds + 1));
            }}
          >
            +
          </Pagination.Item>
        </Pagination>
        {configView[page]}
      </Form>
    </>
  );
}

export default NewGame;
