import { interpolateColors } from "utils/color-generator";
import { suggestedRangeForBaseline } from "./utils";

const compute = ({ baseline, inputControls, selection, scheme }) => {
  /* 
      computes the value given a baseline key
      takes a Lamda function to compute the outcome
    */
  const computeValueForBaseline = (
    title,
    handler = (baselineValue, totalValueChange) => baselineValue + totalValueChange
  ) => {
    let baselineValue = Object.values(baseline).find((e) => e.title === title)?.value ?? 0;

    var totalValueChange = 0;

    // Loop over all rows within the selection
    for (var controlTitle in inputControls) {
      // the the selected input for the category
      let activeOption = activeOptionForInputControl(controlTitle);
      if (!activeOption) continue; // Skip if no active option found

      // Baseline Values entered into the selection
      const idx = Object.values(baseline ?? {}).findIndex((e) => e.title === title);

      let valueChange = Number(Object.values(activeOption?.deltaValues ?? {})[idx]?.value ?? 0);

      // apply that value to total value change
      totalValueChange += valueChange;
    }

    // return result of the lambda function
    return handler(baselineValue, totalValueChange);
  };

  // gets the currently selected option on the category
  const activeOptionForInputControl = (title) => {
    let control = inputControls[title];
    if (!control || !control.options || selection[title] === undefined || selection[title] < 0) {
      return null;
    }
    return control.options[selection[title]] || null;
  };

  // compute the percent change in values, to be displayed in the chart
  const computeCumulativeChartDataForBaseline = (title) =>
    computeValueForBaseline(
      title,
      (baselineValue, totalValueChange) => totalValueChange + baselineValue // (totalValueChange / baselineValue) * 100
    );

  // slightly modified mapping of the baseline values applying color scheme
  var viewModels = Object.values(baseline ?? {}).map((model) => {
    const colors = interpolateColors(Object.values(baseline ?? {}).length, scheme);
    model.color = colors[Object.values(baseline ?? {}).indexOf(model)] ?? "000";
    return model;
  });

  // const values = Object.values(baseline).map((e) =>
  //   computeCumulativeChartDataForBaseline(e.title)
  // );

  // const X_MIN = Math.min(...values, 0, min).toFixed() ?? null;
  // const X_MAX = max ?? null; // Math.max(...values, 10);

  const ranges = {};
  Object.keys(baseline).forEach((input) => {
    ranges[input] = suggestedRangeForBaseline(input, baseline, inputControls);
  });

  const suggestedRange = (index) => ranges[index]; // suggestedRangeForBaseline(index, baseline, inputControls);

  function formatRange(val, index) {
    const min = baseline[index]?.chartMinX || suggestedRange(index)?.min;
    if (val <= min) return [val, 0];
    return [min, val];
  }

  const dataForBaseline = (baseline, index) => {
    const { title } = baseline;
    const value = computeCumulativeChartDataForBaseline(title);
    return formatRange(value, index);
  };

  const chartDataForBaseline = (value, index) => {
    const { title, suffix } = value;
    const dataValues = [dataForBaseline(value, index)];
    return {
      labels: [`${title} ${suffix ?? ""}`],
      datasets: [
        {
          data: dataValues,
          borderColor: [viewModels.map((e) => e.color)[index]],
          backgroundColor: [viewModels.map((e) => e.color)[index]],
        },
      ],
    };
  };

  return {
    suggestedRange,
    computeCumulativeChartDataForBaseline,
    chartDataForBaseline,
    dataForBaseline,
    viewModels,
    baseline,
    selection,
    inputControls,
  };
};

export default compute;
