import { Stack, FormGroup, FormLabel, TextField, FormControlLabel, Checkbox } from "@mui/material";

import ModelsPicker from "sections/admin/models/Picker";
import ColorSchemePicker from "components/ColorSchemePicker";
import { interpolateColors } from "utils/color-generator";

const PointsForm = ({ config, onChange }) => {
  const mode = config.mode ?? "";
  const models = config.models ?? [];
  const scheme = config.scheme ?? "";
  const matchValue = config.matchValue ?? 1000;

  var viewModels = models.map((model) => {
    const colors = interpolateColors(models.length, scheme);
    model.color = colors[models.indexOf(model)] ?? "000";
    return model;
  });

  return (
    <Stack>
      <FormGroup className="mb-3">
        <ColorSchemePicker scheme={scheme} onChange={onChange} />
      </FormGroup>

      <FormGroup className="mb-3">
      <TextField
          id="outlined-number"
          label="Round Title"
          InputLabelProps={{
            shrink: true,
          }}
          value={config.prompt}
          onChange={(e) => {
            onChange({ prompt: e.target.value });
          }}
          placeholder="Name or Prompt for the round."
          sx={{ pb: 2 }}
        />

        <TextField
          id="outlined-number"
          label="Match Value"
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          value={matchValue || 0}
          onChange={(e) => {
            onChange({ matchValue: Number(e.target.value || 0) });
          }}
          placeholder="Amount of points to allocate"
        />
      </FormGroup>
      <FormGroup>
        <FormLabel>Social Issues</FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={config.shuffle}
              onChange={(e) => {
                onChange({ shuffle: e.target.checked });
              }}
            />
          }
          label="Shuffle"
        />

        <ModelsPicker
          mode={mode}
          value={viewModels}
          onChange={(e) => {
            onChange({ models: e.models || [] });
          }}
        />
      </FormGroup>
    </Stack>
  );
};

export default PointsForm;
