import React from "react";

import { Stack, Button, Typography, TextField, Radio, Box, Paper } from "@mui/material";
import { IconPicker } from "utils/fontawesome";
import MoreMenu from "./MoreMenuButton";

const MatrixRow = React.memo(
  ({ item: { title, icon, description, options }, index, baseline, onChange, addRow, dropRow }) => {
    // add option to this category
    const onAdd = (template) => {
      var results = { ...options };
      let idx = Object.keys(results).length ?? 0;
      results[idx] = template ?? {
        title: "",
        description: "",
        deltaValues: Object.values(baseline).map((modifier) => {
          return { title: modifier.title, value: "" };
        }),
      };
      optionsChanged(results);
    };

    // drop option from this category
    const onDrop = (index) => {
      var result = { ...options };
      delete result[index];
      optionsChanged(result);
    };

    const titleChanged = (e) => onChange({ title: e.target.value });
    const descriptionChanged = (e) => onChange({ description: e.target.value });
    const optionsChanged = (data) => onChange({ options: data });

    const optionRowChanged = (index, data) => {
      var results = { ...options };
      results[index] = { ...results[index], ...data };
      optionsChanged(results);
    };

    const handleMoreButton = (option, value, index) => {
      const op = option.toLowerCase();
      switch (op) {
        case "delete":
          return onDrop(index);
        case "duplicate":
          return onAdd(value);
        default:
          return console.warn("unknown option: ", op);
      }
    };

    return (
      <Stack direction="row" spacing={1} key={index}>
        {/* <Icon icon={icon} color={color} /> */}
        <Stack spacing={1} minWidth={150}>
          <IconPicker
            value={icon}
            onChange={(v) => {
              onChange({ icon: v });
            }}
          />
          <TextField value={title} onChange={titleChanged} label="Name" size="small" />
          <TextField
            id="outlined-multiline-static"
            label="Description"
            multiline
            // minRows={4}
            // maxRows={8}
            value={description}
            placeholder="Describe the effects of selecting this category..."
            size="small"
            onChange={descriptionChanged}
          />
        </Stack>
        <Stack spacing={2}>
          <Stack direction="row" alignItems="center">
            <Typography>Options:</Typography>
            <Box
              sx={{
                display: "flex",
                width: "100%",
              }}
            />

            <MoreMenu
              handleOption={(option) => {
                const op = option.toLowerCase();
                switch (op) {
                  case "delete":
                    return dropRow(index);
                  case "duplicate":
                    return addRow({ title, icon, description, options });
                  default:
                    console.warn("unknown option: ", op);
                    break;
                }
              }}
            />
          </Stack>

          {Object.values(options ?? {}).map((option, index) => {
            return (
              <OptionRow
                key={`${index}`}
                config={{ ...option, index }}
                baseline={baseline}
                onChange={(data) => {
                  optionRowChanged(index, data);
                }}
                handleOption={(op) => handleMoreButton(op, option, index)}
              />
            );
          })}
          <Button variant="outlined" onClick={() => onAdd()}>
            + Add Option
          </Button>
        </Stack>
      </Stack>
    );
  }
);

const OptionRow = React.memo(
  ({ config: { index, title, description, deltaValues }, baseline, onChange, handleOption }) => {
    const titleChanged = (data) => onChange({ title: data });
    const descriptionChanged = (data) => onChange({ description: data });
    const deltasChanged = (data) => onChange({ deltaValues: data });

    return (
      <Box sx={{ elevation: 0, display: "flex" }} key={index} component={Paper}>
        <Stack spacing={1} padding={1}>
          <Stack direction="row" alignItems="center">
            <Typography>Option {index + 1}</Typography>
            <MoreMenu
              handleOption={(option) =>
                handleOption(option, { title, description, deltaValues }, index)
              }
            />
          </Stack>
          <Stack spacing={1} direction="row" sx={{ width: "100%", display: "flex" }}>
            <TextField
              value={title}
              control={<Radio />}
              size="small"
              label={"Title"}
              onChange={(e) => titleChanged(e.target.value)}
            />
            <TextField
              label="Description"
              multiline
              // minRows={1}
              // maxRows={5}
              value={description}
              placeholder="Describe the effects of selecting this option..."
              size="small"
              onChange={(e) => descriptionChanged(e.target.value)}
            />
          </Stack>

          <Typography variant="body2" fontWeight="semibold">
            Delta Values:
          </Typography>

          <Stack direction="row" spacing={1}>
            <DeltaInputs baseline={baseline} config={deltaValues} onChange={deltasChanged} />
          </Stack>
        </Stack>
      </Box>
    );
  }
);

const DeltaInputs = ({ baseline, config, onChange }) => {
  const deltaChanged = (index, data) => {
    const templatedResults = Object.entries(baseline ?? {}).map(([key, modifier]) => {
      return {
        title: modifier.title,
        value: deltaValueForBaselineKey(key),
      };
    });

    var results = { ...templatedResults };
    // copy the element applying the new data
    var ele = { ...results[index], ...data };
    // reassign the element at the index
    results[index] = ele;
    // call onChange to parent
    onChange(results);
  };

  const deltaValueForBaselineKey = (key) => {
    let obj =
      Object.values(config ?? {})[key] ??
      // !NOTE: we'll phase this out, but helps with the migration to prevent data loss on column change
      Object.values(config ?? {}).find((e) => e.title === key);

    return obj?.value ?? 0;
  };

  return Object.values(baseline ?? {}).map(({ title, value }, index) => (
    <TextField
      key={index}
      type="number"
      input={title}
      value={deltaValueForBaselineKey(index)}
      size="small"
      label={`Δ ${title}`}
      onChange={(e) => {
        deltaChanged(index, { key: title, value: e.target.value });
      }}
    />
  ));
};

export default MatrixRow;
